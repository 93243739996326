<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>{{ formTitle }}</v-card-title>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'ClientSettings', query: { tab: 'ct'} }" color="green" rounded
                >
                {{$t('Cancel')}}
                </v-btn>
                <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
                rounded
                >
                {{$t('Save')}}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.name"
                            :label="$t('Name')"
                            :rules="rules.name"
                            required
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-textarea
                            v-model="item.description"
                            :label="$t('Description')"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {

    data() {
        return {
            valid: true,
            item: {},
            id:"",
            loading:false,
            rules: {
                name:[
                    value => !!value || this.$t('Required.'),
                    value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                ],
            },
        }
    },

    computed: {
        formTitle () {
            return this.id == "" ? this.$t('Add type of Client') : this.$t('Edit type of Client')
        },
    },

    created: function()
    {
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getItem();
        }
    },

    methods: {
        getItem()
        {
            this.loading = true
            let uri = this.$urlPrefix + '/ClientType/Get?id=' + this.id;
            this.axios.get(uri).then((response) => {
                this.item = response.data;
                this.loading = false
            });
        },
        save () {
            if(this.$refs.form.validate()){
                this.loading = true
                if (this.id != "") {
                    let uri = this.$urlPrefix + '/ClientType/Update/';
                    this.axios.put(uri, this.item).then((response) => {
                        if(response.status == 204 || response.status == 200){
                                this.loading = false
                                this.$router.push({name: 'ClientSettings', query: { tab: 'ct'}});
                        }
                    });
                } else {
                    let uri = this.$urlPrefix + '/ClientType/Add';
                    this.axios.post(uri, this.item).then((response) => {
                        if(response.status == 200){
                            this.item = {}
                            this.loading = false
                            this.$router.push({name: 'ClientSettings', query: { tab: 'ct'}});
                        }
                    });
                }
            }
        },
    }
}
</script>
